window.required_fields_valid = function() {
    var valid = true
    $('[required]').each(function () {
        if ($(this).is(':invalid') || !$(this).val()) {
            $(this).removeClass('is-valid')
            $(this).addClass('is-invalid')
            valid = false
        }
    });
    return valid
}

window.display_required_errors = function(element) {
    if (element.is(':valid') && element.val()) {
        element.removeClass('is-invalid')
        element.addClass('is-valid')
    } else {
        element.removeClass('is-valid')
        element.addClass('is-invalid')
    }
}
    

window.loadDatePickers = function () {
    $.datetimepicker.setLocale('fr')
    $(".date-picker").datetimepicker({
        mask: true,
        format: 'd/m/Y',
        timepicker: false,
        todayButton: false,
        minDate: '1900/01/01',
        maxDate:'+1971/01/01',
        yearStart: '1900',
        yearEnd: new Date().getFullYear()+1
    });

    $(".date-picker").on('focus click', function() {
        if ($(this).val()=="__/__/____") $(this)[0].setSelectionRange(0, 0);
    });
}

window.setCookie = function (key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

window.getCookie = function (key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

window.eraseCookie = function (key) {
    var keyValue = getCookie(key);
    setCookie(key, keyValue, '-1');
}

